const React = require('react');
const isWebpSupported = require('supports-webp');
const { ContextProvider } = require('./src/context');
const { isDeclined } = require('./src/helpers/storage');

const checkDeclaine = () => {
  if (isDeclined() && window.gaData) {
    const propertyId = Object.keys(window.gaData)[0];
    window[`ga-disable-${propertyId}`] = true;

    document.cookie.split('; ').forEach((el) => {
      const key = el.split('=')[0];

      if (!['_ga', '_gid', '_gat'].includes(key)) return false;

      document.cookie = key + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';
    });
  }
};

exports.onClientEntry = () => {
  checkDeclaine();

  const htmlEl = document.documentElement;

  isWebpSupported.then((supported) => {
    if (supported) {
      htmlEl.classList.add('webp');
    } else {
      htmlEl.classList.add('no-webp');
    }
  });
};

exports.wrapRootElement = ({ element, props }) => {
  return <ContextProvider {...props}>{element}</ContextProvider>;
};
