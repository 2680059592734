import React, { useReducer } from 'react';

const StateContext = React.createContext();
const DispatchContext = React.createContext();

const INITIAL_STATE = {
  curModal: null,
};

export const ACTIONS = {
  setGetCirrusoftModal: 'SET_GET_CIRRUSOFT_MODAL',
  setEmployeeModal: 'SET_EMPLOYEE_MODAL',
  setSubscribeModal: 'SET_SUBSCRIBE_MODAL',
};

export const ACTIONS_CREATORS = {
  setGetCirrusoftModal: (value) => ({
    type: ACTIONS.setGetCirrusoftModal,
    value,
  }),
  setEmployeeModal: (value) => ({
    type: ACTIONS.setEmployeeModal,
    value,
  }),
  setSubscribeModal: (value) => ({
    type: ACTIONS.setSubscribeModal,
    value,
  }),
};

export const MODALS = {
  GET_CIRRUSOFT: 'GET_CIRRUSOFT',
  EMPLOYEE: 'EMPLOYEE',
  SUBSCRIBE: 'SUBSCRIBE',
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.setGetCirrusoftModal: {
      return { ...state, curModal: action.value ? MODALS.GET_CIRRUSOFT : null };
    }
    case ACTIONS.setEmployeeModal: {
      return { ...state, curModal: action.value ? MODALS.EMPLOYEE : null };
    }
    case ACTIONS.setSubscribeModal: {
      return { ...state, curModal: action.value ? MODALS.SUBSCRIBE : null };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

export const ContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        {children}
      </DispatchContext.Provider>
    </StateContext.Provider>
  );
};

export const useContextState = () => {
  const context = React.useContext(StateContext);

  if (context === undefined) {
    throw new Error('useContextState must be used within a ContextProvider');
  }

  return context;
};

export const useContextDispatch = () => {
  const context = React.useContext(DispatchContext);

  if (context === undefined) {
    throw new Error('useContextDispatch must be used within a ContextProvider');
  }

  return context;
};
