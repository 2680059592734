import {
  COOKIES_ANSWER_KEY,
  COOKIES_ACCEPT_KEY,
  COOKIES_DECLINE_KEY,
} from 'constants/storage';

export const setConfirmed = () =>
  localStorage.setItem(COOKIES_ANSWER_KEY, COOKIES_ACCEPT_KEY);

export const setDecline = () =>
  localStorage.setItem(COOKIES_ANSWER_KEY, COOKIES_DECLINE_KEY);

export const getAnswer = () => localStorage.getItem(COOKIES_ANSWER_KEY);

export const isConfirmed = () =>
  getAnswer() &&
  localStorage.getItem(COOKIES_ANSWER_KEY) === COOKIES_ACCEPT_KEY;

export const isDeclined = () =>
  getAnswer() &&
  localStorage.getItem(COOKIES_ANSWER_KEY) === COOKIES_DECLINE_KEY;
